import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import st from "../../styles/components/Auth.module.scss";
import { useRouter } from "next/router";
import usePopup from "../../hooks/usePopup";
import useLoader from "../../hooks/useLoader";
import useLanguage, { Dict, useDictionary } from "../../hooks/useLanguage";
import StorageService from "../../lib/services/Storage.Service";
import { useUserSession } from "../../hooks/useUserSession";
import { NODE_API2 } from "@lib/api/nodeApi";
import { useLocale } from "@lib/tools/helpers";
import SignupDropdown from "@items/SignupDropdown";
import { UseSessionOptions } from "next-auth/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useMobile from "@hooks/useMobile";
import { Input } from "@components/new-ui/input";
import { Button } from "@components/new-ui/button";
import {
  formValidation,
  PasswordValidationTooltip,
} from "./password-validation";
import { cn } from "@lib";
import { getSessionData } from "@hooks/useSessions";

export default function Register() {
  const isMobile = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 525,
    breakPointThree: 100,
  });

  const {
    password_validation_length,
    password_validation_lowercase,
    password_validation_uppercase,
    password_validation_special,
    form_validation_password_minlength,
    form_validation_password_required,
    form_validation_password_lowercase,
    form_validation_password_uppercase,
    form_validation_password_special,
    form_validation_username_required,
    form_validation_username_minlength,
    form_validation_email_required,
    form_validation_email_valid,
  } = useDictionary(
    "settings",
    "password_validation_length",
    "password_validation_lowercase",
    "password_validation_uppercase",
    "password_validation_special",
    "password_validation_match",
    "form_validation_password_minlength",
    "form_validation_password_required",
    "form_validation_password_lowercase",
    "form_validation_password_uppercase",
    "form_validation_password_special",
    "form_validation_username_required",
    "form_validation_username_minlength",
    "form_validation_email_required",
    "form_validation_email_valid"
  );

  const formSchema = z.object({
    username: z
      .string()
      .min(1, { message: form_validation_username_required })
      .min(formValidation.username.minLength, {
        message: form_validation_username_minlength,
      }),
    password: z
      .string()
      .min(1, { message: form_validation_password_required })
      .min(formValidation.password.minLength, {
        message: form_validation_password_minlength,
      })
      .regex(formValidation.password.patterns.lowercase, {
        message: form_validation_password_lowercase,
      })
      .regex(formValidation.password.patterns.uppercase, {
        message: form_validation_password_uppercase,
      })
      .regex(formValidation.password.patterns.specialChar, {
        message: form_validation_password_special,
      }),
    email: z
      .string()
      .min(1, { message: form_validation_email_required })
      .email({ message: form_validation_email_valid }),
    referral: z.string().optional(),
    terms: z
      .boolean()
      .default(false)
      .refine((val) => val === true, {
        message: "Terms must be accepted",
      }),
  });

  type FormValues = z.infer<typeof formSchema>;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onSubmit",
    defaultValues: {
      email: "",
      username: "",
      password: "",
      referral: "",
      terms: false,
    },
  });

  const [dropdownActive, setDropdownActive] = useState(false);
  const L = useLanguage(["forms", "common"]);
  const { signInToken: login, addTokenHeader } = useUserSession(
    {} as UseSessionOptions<any>
  );
  const Router = useRouter();
  const setMessage = usePopup();
  const inputRef = useRef(null);
  const language = useLocale();

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [displayMsg, setLoader] = useLoader(L("register", {}));

  const password = watch("password");

  const passwordValidationState = useMemo(
    () => ({
      length: password?.length >= formValidation.password.minLength,
      lowercase: formValidation.password.patterns.lowercase.test(
        password || ""
      ),
      uppercase: formValidation.password.patterns.uppercase.test(
        password || ""
      ),
      special: formValidation.password.patterns.specialChar.test(
        password || ""
      ),
    }),
    [password]
  );

  const onSubmit = async (formData: FormValues) => {
    const data = {
      device: await getSessionData(),
      username: formData.username,
      email: formData.email,
      password: formData.password,
      affiliate_code: formData.referral ?? StorageService.getReferralCode(),
      agreed: formData.terms,
      language,
    };

    setLoader(true);

    try {
      const res = await NODE_API2.post(
        "v1/user/sign-up",
        data,
        addTokenHeader()
      );

      if (res.data.success) {
        setMessage({ code: "responses.successful-register", type: 1 });
        StorageService.removeReferralCode();
        login(res.data.tokens);
        setTimeout(() => {
          Router.replace(Router.asPath.split("?")[0], undefined, {
            shallow: true,
          });
        }, 500);
      } else {
        setMessage({ code: "responses." + res.data.error, type: 0 });
      }
    } catch (e) {
      const msg = e?.response?.data?.error || "er_network";
      setMessage({ code: "responses." + msg, type: 0 });
    } finally {
      setLoader(false);
    }
  };

  const handleTermsAndServiceClick = () => {
    window.open("/terms", "_blank");
  };

  // blocks opening the collapse
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const code = StorageService.getReferralCode();
    if (code) {
      setValue("referral", code);
      setDropdownActive(true);
    }
  }, [setValue]);

  return (
    <form
      className="grid gap-5"
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid gap-[10px]">
        <h2 className="text-base">{L("register-title", {})}</h2>
        <p className="text-font-color-two text-[13px]">
          {L("register-greet", {})}
        </p>
      </div>

      <div className="grid gap-4">
        <div className="grid gap-4 relative">
          <Dict
            name="username"
            as="p"
            section="forms"
            className="text-[13px] font-medium text-input-label-color-light text-opacity-75"
          />

          <div className="grid gap-1 relative">
            <div>
              <Input
                aria-invalid={!!errors.username}
                {...register("username", { required: true })}
              />
            </div>
            {errors.username && (
              <p className="text-[#EC4848] text-[13px]">
                {errors.username.message}
              </p>
            )}
          </div>
        </div>

        <div className="grid gap-4">
          <Dict
            name="email"
            as="p"
            section="forms"
            className="text-[13px] font-medium text-input-label-color-light text-opacity-75"
          />

          <div className="grid gap-1 relative">
            <div>
              <Input
                aria-invalid={!!errors.email}
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
            </div>
            {errors.email && (
              <p className="text-[#EC4848] text-[13px]">
                {errors.email.message}
              </p>
            )}
          </div>
        </div>

        <div className="grid gap-4">
          <Dict
            name="password"
            as="p"
            section="forms"
            className="text-[13px] font-medium text-input-label-color-light text-opacity-75"
          />

          <PasswordValidationTooltip
            validationRules={[
              { key: "length", text: password_validation_length },
              { key: "lowercase", text: password_validation_lowercase },
              { key: "uppercase", text: password_validation_uppercase },
              { key: "special", text: password_validation_special },
            ]}
            passwordValidationState={passwordValidationState}
            forceShow={isPasswordFocused}
            placement={isMobile ? "top-end" : "right"}
          >
            <div>
              <Input
                type="password"
                aria-invalid={!!errors.password}
                onFocus={() => setIsPasswordFocused(true)}
                {...register("password", {
                  required: true,
                  onBlur: () => setIsPasswordFocused(false),
                })}
              />
            </div>
          </PasswordValidationTooltip>
        </div>

        <SignupDropdown
          defaultActiveState={!!watch("referral") || dropdownActive}
          classType={["expand"]}
          dropDownStyle="register-modal"
          inputRef={inputRef}
        >
          <div className="grid gap-1 relative">
            <div>
              <Input
                aria-invalid={!!errors.referral}
                {...register("referral")}
              />
            </div>
            {errors.referral && (
              <p className="text-[#EC4848] text-[13px]">
                {errors.referral.message}
              </p>
            )}
          </div>
        </SignupDropdown>

        <div className={st["checkbox-container"]}>
          <input type="checkbox" id="terms" {...register("terms")} />

          <label
            htmlFor="terms"
            className={cn(!!errors.terms && "before:!border-[#EC4848]")}
          >
            {L("register-checkbox", {})}
            <p
              onClick={handleTermsAndServiceClick}
              className="text-[#5e679e] text-[13px] p-0 inline-block pl-1 [&_span]:text-[#5e679e] [&_span]:hover:text-[#9ca4cf] [&_span]:cursor-pointer [&_span]:transition-colors [&_span]:duration-200"
            >
              <Dict name="register-terms" as="span" section="forms" />
            </p>
          </label>
        </div>

        <Button type="submit">{displayMsg}</Button>
      </div>
    </form>
  );
}
