import { useForm } from "react-hook-form";
import useLanguage, { Dict } from "../../hooks/useLanguage";
import API from "../../lib/api/api.js";
import usePopup from "../../hooks/usePopup.js";
import useLoader from "../../hooks/useLoader.js";
import { useRouter } from "next/router";
import { Input } from "@components/new-ui/input";
import { Button } from "@components/new-ui/button";

type FormValues = {
  usernameOrEmail: string;
};

export default function RecoverAccount({ setRecover }) {
  const L = useLanguage(["forms"]);
  const [displayMsg, setLoader] = useLoader(L("recover", {}));
  const setMessage = usePopup();
  const Router = useRouter();

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      usernameOrEmail: "",
    },
  });

  const onSubmit = async (data: FormValues) => {
    setLoader(true);

    try {
      const res = await API.post("user/recover", {
        username: data.usernameOrEmail,
      });

      if (res.data.success) {
        setMessage({ code: "responses.su_recover_message", type: 1 });
        Router.replace(Router.asPath.split("?")[0], undefined, {
          shallow: true,
        });
      } else {
        setMessage({ code: "responses." + res.data.error, type: 0 });
      }
    } catch (error) {
      setMessage({ code: "responses.er_network", type: 2 });
    } finally {
      setLoader(false);
    }
  };

  return (
    <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-[10px]">
        <h2 className="text-base">{L("recover", {})}</h2>
        <p className="text-font-color-two text-[13px]">
          {L("recover-greet", {})}
        </p>
      </div>

      <div className="grid gap-4 relative">
        <Dict
          name="recover_username"
          as="p"
          section="forms"
          className="text-[13px] font-medium text-input-label-color-light text-opacity-75"
        />

        <div>
          <Input {...register("usernameOrEmail")} />
        </div>
      </div>

      <div className="flex flex-col gap-4 pt-[14px] pb-4">
        <Button type="submit">{displayMsg}</Button>

        <div className="flex justify-end">
          <button
            onClick={() => setRecover(false)}
            className="[&_span]:text-[#7179a5] text-[13px]"
          >
            <Dict name="return_to_login" as="span" section="forms" />
          </button>
        </div>
      </div>
    </form>
  );
}
