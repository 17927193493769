
import st from '../styles/items/ModalSpinner.module.scss'
import LoadingSpinner from '../components/Loading/LoadingSpinner';

const ModalSpinner = ({ hide }) => {
    return (
        <div className={`${st["modal-spinner"]} ${hide ? st["hide"] : null}`}>
            <LoadingSpinner type={"slots"}/>
        </div>
    );
};

export default ModalSpinner;
