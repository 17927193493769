import Login from "./login";
import st from "../../styles/components/Auth.module.scss";
import { ModalNav } from "../Modal";
import useDetectOutside from "../../hooks/useDetectOutside";
import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "../../items/Button";
import API, { NODE_API } from "../../lib/api/api";
import usePopup from "../../hooks/usePopup";
import useLanguage from "../../hooks/useLanguage";
import useAuth from "../../hooks/useAuth";
import { handleCatchPopup, removeUrlQueries } from "../../lib/tools/helpers";
import Particles from "@assets/Images/Wallet/particles.gif";
import LoginModalLogo from "../../assets/Images/login-modal.png";
import { useUserSession } from "../../hooks/useUserSession";
import { useSearchParams } from "next/navigation";
import Image from "next/image";
import ModalSpinner from "@items/ModalSpinner";
import TwoFactorInput from "./TwoFactorInput";
import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import SteamIcon from "@assets/icons/general/SteamIcon";
import { LoadingExternalLogin } from "@components/Loading/LoadingExternalLogin";
import StorageService from "@lib/services/Storage.Service";
import { GoogleLogo } from "@assets/icons/general/GoogleLogo";
import { NewLogo, NewLogoIconGlow } from "@assets/icons/general/NewLogo";
import { getSessionData } from "../../hooks/useSessions";
import Register from "./register";

// Function to render the appropriate component based on the given parameter
function RenderSwitch({ param, setTwoFactorInfo, setRecover, recover }) {
  switch (param) {
    case "login":
      return (
        <Login
          setTwoFactorInfo={setTwoFactorInfo}
          setRecover={setRecover}
          recover={recover}
        />
      );
    case "register":
      return <Register />;
    default:
      removeUrlQueries();
      return null;
  }
}

const useGoogleLogin = () => {
  const { signInToken } = useUserSession();
  const query = useSearchParams();
  const loginType = query.get("login-type");
  const jwt = query.get("token");
  const setMessage = usePopup();

  useEffect(() => {
    if (loginType !== "google") return;
    if (!jwt) return;

    signInToken({ jwt })
      .then(() => setMessage({ code: "responses.successful", type: 1 }))
      .catch(handleCatchPopup(setMessage, true))
      .finally(() => {
        removeUrlQueries();
      });
  }, [jwt, loginType, signInToken, setMessage]);
};

//Auth container,
export default function Auth() {
  const query = useSearchParams();
  const method = query.get("method");
  const modal = query.get("modal");
  const tab = query.get("tab");
  const openid = query.get("openid.ns");
  const referralCode = query.get("r");

  if (referralCode) {
    StorageService.setReferralCode(referralCode);
  }

  const modalLogo = LoginModalLogo;

  const { ref } = useDetectOutside(true, () => {
    removeUrlQueries();
  });
  const { signInToken: login } = useUserSession();
  const L = useLanguage(["common", "auth", "forms"]);
  const setMessage = usePopup();
  useAuth(true);

  const [homeBtnMethod, setHomeBtnMethod] = useState(false);
  const [twoFactorInfo, setTwoFactorInfo] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [spinnerVisibility, setSpinnerVisibility] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [recover, setRecover] = useState(false);
  const loginType = query.get("login-type");
  const [stateExternalLogin, setStateExternalLogin] = useState({
    isLoading: !!openid || !!loginType,
    type: loginType || undefined,
  });

  const securityCheck = useMemo(
    () =>
      twoFactorInfo.securityCheck
        ? { security_code: twoFactorCode }
        : { code: twoFactorCode },
    [twoFactorCode, twoFactorInfo.securityCheck],
  );

  //First processes for steam
  const steam = useCallback(() => {
    API.post("steam/initiate")
      .then((res) => {
        window.location.replace(res.data.url);
      })
      .catch(handleCatchPopup(setMessage));
  }, [setMessage]);

  const metaMaskLogin = useCallback(async () => {
    setStateExternalLogin({ isLoading: true, type: "metamask" });

    try {
      const provider = window.ethereum;
      if (!provider) {
        setMessage({ code: "responses.metamask_not_installed", type: 0 });
        throw new Error(
          "MetaMask is not installed. Please install it to proceed.",
        );
      }

      if (!provider.isMetaMask) {
        setMessage({ code: "responses.only_meta_mask", type: 0 });
        throw new Error("This feature is only available for MetaMask users.");
      }
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });

      let dataOne = {
        address: accounts[0],
        stage: 1,
      };

      const resOne = await API.post("metamask/initiate", dataOne);

      if (resOne.data.error) {
        setMessage({ code: "responses." + resOne.data.error, type: 0 });
      }

      if (resOne.data.success) {
        let localizedMessage = L("meta_mask_message");
        let message = `${localizedMessage} ${resOne.data.hash}`;

        const signature = await provider.request({
          method: "personal_sign",
          params: [message, accounts[0]],
        });

        let dataTwo = {
          signature: signature,
          localized_message: localizedMessage,
          address: accounts[0],
          stage: 2,
        };

        const resTwo = await API.post("metamask/initiate", dataTwo);
        if (resTwo.data.tokens) {
          login(resTwo.data.tokens);
          setMessage({ code: "responses.successful", type: 1 });

          removeUrlQueries();
        } else {
          setMessage({ code: "responses." + resTwo.data.error, type: 0 });
        }
        setStateExternalLogin({ isLoading: false, type: "" });
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setMessage({ code: "responses.er_network", type: 0 });
        setStateExternalLogin({ isLoading: false, type: "" });
      }
    }
  }, [L, login, setMessage]);

  //Login 2fa
  const twoFactorLogin = useCallback(async () => {
    let data = {
      device: await getSessionData(),
      password: twoFactorInfo.password,
      username: twoFactorInfo.username,
      ...securityCheck,
    };

    if (hasError) setHasError(false);
    setSpinnerVisibility(false);

    startTransition(() => {
      API.post("user/log-in", data)
        .then(async (res) => {
          if (res.data.success) {
            await login(res.data.tokens);

            setMessage({ code: "responses.successful", type: 1 });
            setIsAuthenticated(true);

            removeUrlQueries();
          }
          if (res.data.error) {
            setMessage({ code: "responses." + res.data.error, type: 0 });
            setSpinnerVisibility(true);
            setHasError(true);
          }
          return;
        })
        .catch((err) => {
          setSpinnerVisibility(true);
          if (err.response?.status !== 401) {
            setMessage({ code: "responses.er_network", type: 0 });
          }
        })
        .finally(() => {
          setTwoFactorCode("");
        });
    });
  }, [
    twoFactorInfo.password,
    twoFactorInfo.username,
    securityCheck,
    hasError,
    setMessage,
    login,
  ]);

  // google
  useGoogleLogin();

  const handleTermsAndServiceClick = () => {
    window.open("/terms", "_blank");
  };

  // steam
  const loginSteam = useCallback(() => {
    const queryString = Array.from(query.entries())
      .map(([key, value]) => {
        if (key === "modal" || key === "tab") return;

        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&")
      .trim()
      .substring(1);

    return API.get(`steam/initiate?${queryString}`).then((res) => {
      if (!res?.data?.tokens) throw res.data;

      return login(res.data.tokens);
    });
  }, [login, query]);

  useEffect(() => {
    if (!openid) return;
    if (stateExternalLogin.isLoading && stateExternalLogin.type) return;
    if (stateExternalLogin.type === "done") return;

    setStateExternalLogin({ isLoading: true, type: "steam" });

    loginSteam()
      .then(() => {
        setMessage({ code: "responses.successful", type: 1 });
      })
      .catch(handleCatchPopup(setMessage))
      .finally(() => {
        removeUrlQueries();
      });
  }, [
    loginSteam,
    openid,
    setMessage,
    stateExternalLogin.isLoading,
    stateExternalLogin.type,
  ]);

  useEffect(() => {
    if (method && !homeBtnMethod) {
      if (method === "steam") {
        steam();
      }
      if (method === "metamask") {
        metaMaskLogin();
      }
      setHomeBtnMethod(true);
    }
  }, [homeBtnMethod, metaMaskLogin, method, steam]);

  useEffect(() => {
    if (twoFactorCode.length === 6) {
      twoFactorLogin();
    }
  }, [twoFactorCode, twoFactorLogin]);

  if (stateExternalLogin.isLoading)
    return <LoadingExternalLogin type={stateExternalLogin.type || "default"} />;

  //Switch between sections and create the menu
  return (
    <>
      <div
        ref={ref}
        className={`${st["auth-container"]} ${
          twoFactorInfo ? st["active-two-factor"] : null
        }`}
      >
        <ModalSpinner hide={spinnerVisibility} />
        <div className={st["left-container"]}>
          <div className={st["logo-container"]}>
            <Image
              src={modalLogo}
              width={450}
              quality={100}
              alt="Rainbet"
              className={st["image-block"]}
            />
          </div>
          <div className={st["particles-background"]}>
            <Image
              quality={100}
              src={Particles}
              alt="Particles"
              objectFit="cover"
              height={750}
              unoptimized
              className={st["image-block"]}
            />
          </div>
          <div className={st["left-logo"]}>
            <NewLogoIconGlow width={220} height={220} />
          </div>
          <h3 className={st["ready-to-play"]}>{L("left-greet")}</h3>
          <div className={st["terms-container"]}>
            {L("terms_conditions")}

            <p
              className={st["terms-conditions-logo"]}
              onClick={handleTermsAndServiceClick}
            >
              {L("register-terms")}
            </p>
          </div>
        </div>
        <div className={st["right-container"]}>
          <div className={st["auth-logo-container"]}>
            <NewLogo />
          </div>

          {!twoFactorInfo ? (
            <>
              <ModalNav
                navItems={[
                  { query: { modal: "auth", tab: "login" }, text: "Login" },
                  {
                    query: { modal: "auth", tab: "register" },
                    text: "Register",
                  },
                ]}
              />

              {/* Renders the tabs */}
              {modal === "auth" && (
                <RenderSwitch
                  param={tab}
                  setTwoFactorInfo={setTwoFactorInfo}
                  setRecover={setRecover}
                  recover={recover}
                />
              )}
              {(tab === "login" || tab === "register") && !recover && (
                <div className={st["alt-signin"]}>
                  <h3>{L("other_top")}</h3>

                  <div className={st["btn-content"]}>
                    <Button
                      method={steam}
                      classType={["meta-steam"]}
                      icon={<SteamIcon />}
                    />
                    <Button
                      method={metaMaskLogin}
                      classType={["meta-steam"]}
                      icon={<MetaMaskIcon />}
                    />
                    <Button
                      method={() => {
                        window.location.href = `${NODE_API.getUri()}/v1/auth/google`;
                      }}
                      classType={["meta-steam"]}
                      icon={<GoogleLogo />}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <TwoFactorInput
                setTwoFactorCode={setTwoFactorCode}
                hasError={hasError}
                isAuthenticated={isAuthenticated}
                initialMessage={twoFactorInfo.securityCheck}
                username={twoFactorInfo.username}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
