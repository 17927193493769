import { cn } from "@lib";
import { ComponentProps, forwardRef } from "react";

export const Input = forwardRef<HTMLInputElement, ComponentProps<"input">>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "border border-white border-opacity-5 border-solid w-full outline-none duration-75 transition-all aria-[readonly=false]:focus:border-primary-stroke-color overflow-hidden [background:radial-gradient(76.33%_100%_at_50%_100%,rgb(38,44,82,0.8)_0%,rgba(38,44,82,0)_100%),rgba(255,255,255,0.05)] px-5 rounded-md font-medium h-[50px] text-white text-sm aria-[readonly=true]:opacity-50 aria-[invalid=true]:!border-[#EC4848] aria-[invalid=true]:focus:border-[#EC4848] flex items-center",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";
