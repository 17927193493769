import { useEffect, useRef, useState } from "react";
import st from "../styles/items/Expand.module.scss";
import Button from "./Button";
import gsap from "gsap";
import useLanguage from "@hooks/useLanguage";

export default function SignupDropdown(props) {
  const {
    children,
    text = " ",
    ValueIcon = null,
    icon2 = false,
    defaultActiveState = false,
    inputRef,
  } = props;

  const [active, setActive] = useState(false);
  const contentToggle = useRef(null);
  const contentDisplay = useRef(null);
  const contentContainerRef = useRef(null);

  const L = useLanguage(["forms", "common"]);

  useEffect(() => {
    setActive(defaultActiveState);
  }, [defaultActiveState]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [active, inputRef]);

  useEffect(() => {
    if (active && contentDisplay.current) {
      gsap.set(contentContainerRef.current, {
        visibility: "hidden",
        height: "auto",
      });

      gsap.set(contentContainerRef.current, { visibility: "visible" });
    }
  }, [active, children, inputRef]);

  return (
    <>
      <div className={st.dropdownContainer}>
        <div
          className={st.dropdownLabel}
          onClick={() => {
            setActive((item) => !item);
          }}
        >
          {L("register-referral")}
        </div>
        <div className={st.dividingLine} />
        <div className={st.arrowButton}>
          <Button
            forwardedRef={contentToggle}
            text={text}
            icon={"arrow"}
            icon2={icon2}
            method={setActive}
            param={!active}
            active={active}
            ValueIcon={ValueIcon}
          />
        </div>
      </div>
      {active ? (
        <div
          ref={contentDisplay}
          className={`${st["drop-down-container"]} ${active ? st["active"] : ""}`}
        >
          <div ref={contentContainerRef}>{children}</div>
        </div>
      ) : null}
    </>
  );
}
