import { useCallback, useState, useEffect } from "react";
import LoadingSpinner from "../components/Loading/LoadingSpinner";

// Used when content is submitted to indicate loading to the user.
export default function useLoader(initialText, style) {
  const [currentText, setCurrentText] = useState(initialText);
  const [displayValue, setDisplayValue] = useState(initialText);
  const [loading, setLoading] = useState(false);

  const isLoading = useCallback(
    (value) => {
      setLoading(value);
      if (value) {
        // Not sure if gifs/jsx will work with the buttons, but try.
        setDisplayValue(<LoadingSpinner type={"button"} style={style} />);
      } else {
        setDisplayValue(currentText);
      }
    },
    [currentText, style]
  );

  const updateDisplayValue = useCallback((newText) => {
    setCurrentText(newText);
  }, []);

  useEffect(() => {
    if (!loading) {
      setDisplayValue(currentText);
    }
  }, [currentText, loading]);

  return [displayValue, isLoading, updateDisplayValue];
}
