import { Button } from "@components/new-ui/button";
import { Input } from "@components/new-ui/input";
import useLanguage, { Dict } from "@hooks/useLanguage";
import useLoader from "@hooks/useLoader.js";
import usePopup from "@hooks/usePopup.js";
import { getSessionData } from "@hooks/useSessions";
import { useUserSession } from "@hooks/useUserSession";
import API from "@lib/api/api.js";
import { handleCatchPopup, removeUrlQueries } from "@lib/tools/helpers";
import { UseSessionOptions } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Recover from "./recover";

type FormValues = {
  username: string;
  password: string;
};

export default function Login({ setTwoFactorInfo, setRecover, recover }) {
  const L = useLanguage(["forms"]);

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const [displayMsg, setLoader] = useLoader(L("login", {}));
  const setMessage = usePopup();
  const { signInToken: login } = useUserSession({} as UseSessionOptions<any>);
  const query = useSearchParams();
  const recoverQuery = query.get("recover");

  useEffect(() => {
    setRecover(recoverQuery);
  }, [recoverQuery, setRecover]);

  const onSubmit = async (data: FormValues) => {
    setLoader(true);

    try {
      const res = await API.post("user/log-in", {
        device: await getSessionData(),
        ...data,
      });

      if (res?.data?.two_factor) {
        return setTwoFactorInfo({
          password: data.password,
          username: data.username,
        });
      }

      if (!res?.data?.success) {
        if (res.data.error === "er_security_check_required") {
          return setTwoFactorInfo({
            password: data.password,
            username: data.username,
            securityCheck: true,
          });
        }
        return setMessage({ code: "responses." + res.data.error, type: 0 });
      }

      await login(res.data.tokens);

      setMessage({ code: "responses.successful", type: 1 });

      removeUrlQueries();
    } catch (error) {
      handleCatchPopup(setMessage)(error);
    } finally {
      setLoader(false);
    }
  };

  if (recover) return <Recover setRecover={setRecover} />;

  return (
    <>
      <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-[10px]">
          <h2 className="text-base">{L("login", {})}</h2>
          <p className="text-font-color-two text-[13px]">
            {L("login-greet", {})}
          </p>
        </div>
        <div className="grid gap-4">
          <div className="grid gap-4 relative">
            <Dict
              name="username"
              as="p"
              section="forms"
              className="text-[13px] font-medium text-input-label-color-light text-opacity-75"
            />

            <div>
              <Input {...register("username", { required: true })} />
            </div>
          </div>

          <div className="grid gap-4">
            <Dict
              name="password"
              as="p"
              section="forms"
              className="text-[13px] font-medium text-input-label-color-light text-opacity-75"
            />

            <div>
              <Input type="password" {...register("password")} />
            </div>
          </div>

          <div className="flex flex-col gap-4 pt-[14px] pb-4">
            <Button type="submit">{displayMsg}</Button>

            <div className="flex justify-end">
              <button
                onClick={() => setRecover(true)}
                className="[&_span]:text-[#7179a5] text-[13px]"
              >
                <Dict name="recover_account" as="span" section="forms" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
