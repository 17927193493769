import React, { useState, useRef, useEffect } from "react";
import st from "@styles/components/CountDownLink.module.scss";
import API from "@lib/api/api";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import useLanguage from "@hooks/useLanguage";

const CountDownLink = ({ username }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [message, setMessage] = useState("");
  const intervalRef = useRef();
  const L = useLanguage(["auth"]);

  const handleResend = async () => {
    setIsLoading(true);
    try {
      await API.post("/user/resend-email-code", { type: "login", username });
      setCountdown(60);
    } catch (error) {
      setMessage(L("countdown.error"));
      setIsLoading(false);
    } finally {
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      intervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalRef.current);
            setMessage("");
            return 0;
          }
          setMessage(
            `${L("countdown.code_sent_initial")} <h3 className=${st.countdownText}>${prevCountdown - 1}</h3> ${L("countdown.seconds_remaining")}`
          );
          setIsLoading(false);

          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalRef.current);
  }, [countdown, L]);

  return (
    <div className={st.countdownLink}>
      {message ? (
        <p dangerouslySetInnerHTML={{ __html: message }} />
      ) : (
        <p>
          {L("countdown.not_receiving")}{" "}
          {isLoading ? (
            <span className={st.loadingContainer}>
              <LoadingSpinner type={"button"} />
            </span>
          ) : (
            <span onClick={handleResend} className={st.resendLink}>
              {L("countdown.send_again")}
            </span>
          )}
        </p>
      )}
    </div>
  );
};

export default CountDownLink;
